// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_BASEURL.replace('panel/', '')}auth/login`,
  registerEndpoint: `${process.env.REACT_APP_BASEURL.replace('panel/', '')}auth/register`,
  refreshEndpoint: `${process.env.REACT_APP_BASEURL.replace('panel/', '')}auth/refresh-token`,
  logoutEndpoint: `${process.env.REACT_APP_BASEURL.replace('panel/', '')}auth/logout`,

  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
